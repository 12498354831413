#e-commerce-products {

    .page-content {

        > .header {

            .search-wrapper {
                max-width: 48rem;
            }
        }
    }

    #e-commerce-products-table_wrapper {

        #e-commerce-products-table {

            .product-image {
                width: 5.2rem;
                height: 5.2rem;
                border: 1px solid rgba(0, 0, 0, .12);
            }
        }
    }
}