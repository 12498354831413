@mixin firefox-only {
    @-moz-document url-prefix() {
        @content;
    }
}

@mixin ie-only {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}