//
// Base styles
//

.card {
    @include mdc-elevation(1);
}

.card-block {
    padding: $card-spacer-y $card-spacer-x;
}

.card-title {
    margin-top: $card-spacer-y;
    padding-top: $card-spacer-y;
    font-size: 2.4rem;
    font-weight: 400;
}

.card-subtitle {
    margin-bottom: $card-spacer-y;
    padding: 0;
    font-weight: 400;
    font-size: 1.4rem;
}

.card-text {
    margin-bottom: $card-spacer-y;
}

.card-link {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    color: $gray-700;
    margin: $card-spacer-y 0 0 0;
    padding: 0 $card-spacer-x / 2;
    display: inline-block;
    height: 3.6rem;
    box-sizing: border-box;
    letter-spacing: 0.04em;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    line-height: 2.57143;
    margin-left: -($card-spacer-x / 2);

    + .card-link {
        margin-left: $card-spacer-x / 2;
    }
}

//
// Optional textual caps
//

.card-header {
    padding: ($card-spacer-y * 1.5) $card-spacer-x;
}

.card-footer {
    padding: ($card-spacer-y * 1.5) $card-spacer-x;
}

//
// Card groups
//

.card-group {
    @include mdc-elevation(2);
    > .card {
        box-shadow: none;
    }
}

[class^="card-outline-"],
[class*=" card-outline-"] {
    border-style: solid;
    border-width: 1px;
}