// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($background, $border, $active-background: darken($background, 7.5%), $active-border: darken($border, 10%)) {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
    //@include box-shadow($btn-box-shadow);
    @include mdc-elevation(2);
    @include mdc-elevation-transition;

    &:hover {
        color: color-yiq($background);
        background-color: $active-background;
        border-color: $active-border;
        @include autoText($background);
    }

    &:focus,
    &.focus {
        // Avoid using mixin so we can pass custom focus shadow properly
        //@if $enable-shadows {
        //    box-shadow: $btn-box-shadow, 0 0 0 3px rgba($border, .5);
        //} @else {
        //    box-shadow: 0 0 0 3px rgba($border, .5);
        //}
    }

    @include autoText($background, '!important');

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        color: color-yiq($background);
        background-color: $background;
        border-color: $border;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        @include autoText($background);

        background-color: $active-background;
        background-image: none; // Remove the gradient for the pressed/active state
        //border-color: $active-border;
        @include mdc-elevation(8);
    }

    @include ripple-color($background, '!important');

    //i, [class^="icon-"], [class*=" icon-"] {
    //    @include autoText($background,'!important');
    //}
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
    color: $color;
    background-color: transparent;
    background-image: none;
    border-color: $color;

    @include hover {
        //color: $color-hover;
        @include autoText($color);
        background-color: $color;
        border-color: $color;
    }

    &:focus,
    &.focus {
        //box-shadow: 0 0 0 2px rgba($color, .5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        //color: $color-hover;
        @include autoText($color);
        background-color: $color;
        border-color: $color;
    }

    @include ripple-color($color);
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
    @include border-radius($border-radius);
}
